import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PlatformService } from './platform.service';
import { HelpersService } from './helpers.service';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ModalController } from '@ionic/angular';
import { StudentDictionaryDialogComponent } from '../components/student-dictionary-dialog/student-dictionary-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class DictionaryService {
  sentencesCheckedForTranslation = new Subject();
  url;
  checkingTranslationForStudents = false;
  constructor(
    private http: HttpClient,
    private platform: PlatformService,
    private helpers: HelpersService,
    private modalCtrl: ModalController
  ) {
    this.url = this.platform.url;
  }

  checkTextForTranslations(itemToDisassemble, meaningId?) {
    let disassembledSentence = [];
    if (itemToDisassemble) {
      disassembledSentence =
        typeof itemToDisassemble == 'string'
          ? this.disassembleObject(itemToDisassemble)
          : itemToDisassemble;

      const words = disassembledSentence.map((word: any) => {
        const result = {
          word: word.word.replaceAll(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, ''),
          id: word.id,
          meaningId2: meaningId,
        };
        return result;
      });

      this.http
        .post<{ message: string }>(
          this.url + '/api/dictionary/checksentencefortranslations/',
          { words }
        )
        .subscribe((response: any) => {
          const _meaningId = response?.foundWords[0].meaningId;
          disassembledSentence.forEach((word) => {
            const wordInResponse = response.foundWords.find(
              (responseWord) => word.id === responseWord.id
            );
            if (wordInResponse.exists) {
              word.hasTranslation = true;
            }
          });
          if (_meaningId === meaningId) {
            this.sentencesCheckedForTranslation.next({
              sentence: disassembledSentence,
              meaningId,
            });
          }
        });
    }
  }

  disassembleObject(object: string, mode?) {
    let singleWords;
    if (mode !== undefined) {
      singleWords = object.split("'");
    } else {
      singleWords = object
        .replace('/', ' / ')
        .replace('(', '( ')
        .replace(')', ' )')
        .replace(/\s\s+/g, ' ')

        .split(' ');
    }

    const disassembledObject = [];
    singleWords.forEach((element) => {
      disassembledObject.push({
        id: this.helpers.makeid(6),
        word: element.trim(),
        connectedWith: [],
        connectedWord: '',
        connectionColor: '',
        verb: false,
      });
    });
    return disassembledObject;
  }
  findWords(
    wordToFind: any,
    searchThruSentence: any,
    searchMode: any,
    translation: boolean,
    polish: boolean,
    spanish?: boolean
  ) {
    if (wordToFind === '') {
      // this.getWordsList()
      return;
    }
    this.http
      .get<{ words: any }>(
        this.url +
          '/api/dictionary/findWords?wordtofind=' +
          wordToFind
            .toLowerCase()
            .trim()
            .replaceAll(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, '') +
          '&searchthrusentence=' +
          searchThruSentence +
          '&searchmode=' +
          searchMode +
          '&polish=' +
          polish +
          '&spanish=' +
          spanish
      )
      .pipe(
        map((words: any) =>
          words.words.map((word) => ({
            id: word._id,
            word: word.data.word,
            added: word.data.added,
            meanings: word.data.meanings,
          }))
        )
      )
      .subscribe((words: any) => {
        const finalMeanings = [];
        words.forEach((singleWord) => {
          singleWord.meanings?.forEach((meaning) => {
            finalMeanings.push(meaning);
          });
        });
        console.log(
          '🚀 ~ file: dictionary.service.ts:136 ~ DictionaryService ~ .subscribe ~ finalMeanings:',
          finalMeanings
        );
        this.openStudentsDictionaryResultsDialog(
          finalMeanings,
          wordToFind,
          polish ? 'polskim' : spanish ? 'hiszpańskim' : 'angielskim'
        );
      });
  }
  findWordsForStudents(
    wordToFind,
    searchThruSentence,
    searchMode,
    object?,
    fromButton?,
    connected?,
    optionalWordForConnected?,
    connectedNotFound?,
    spanish?
  ) {
    if (wordToFind === '') {
      // this.getWordsList()
      return;
    }
    this.checkingTranslationForStudents = true;
    let wordToFindToSend;
    if (typeof wordToFind == 'object') {
      wordToFindToSend = wordToFind.word
        .toLowerCase()
        .replace('.', '')
        .replace('"', '')
        .replace('"', '')
        // .replace("'", '')
        // .replace("'", '')
        .replace(',', '')
        .replace('?', '')
        .replace('!', '')
        .replace(':', '')
        .replace('’', "'")
        .trim();
    } else {
      wordToFindToSend = wordToFind
        .toLowerCase()
        .replace('.', '')
        .replace(',', '')
        .replace('?', '')
        .replace('!', '')
        .replace(':', '')
        .replace('"', '')
        .replace('"', '')
        // .replace("'", '')
        // .replace("'", '')
        .replace('’', "'")
        .trim();
    }
    this.http
      .get<{ words: any }>(
        this.url +
          '/api/dictionary/findWords?wordtofind=' +
          wordToFindToSend +
          '&searchthrusentence=' +
          searchThruSentence +
          '&searchmode=' +
          searchMode +
          '&spanish=' +
          spanish
      )
      .pipe(
        map((words: any) =>
          words.words.map((word) => ({
            id: word._id,
            word: word.data.word,
            added: word.data.added,
            meanings: word.data.meanings,
          }))
        )
      )
      .subscribe((words: any) => {
        if (words.length === 0 && connected) {
          this.findWordsForStudents(
            optionalWordForConnected,
            false,
            'exact',
            false,
            false,
            false,
            null,
            true,
            spanish
          );
        }

        const finalMeanings = [];
        words.forEach((singleWord) => {
          singleWord.meanings?.forEach((meaning) => {
            finalMeanings.push(meaning);
          });
        });
        console.log('-> finalMeanings', finalMeanings);
        this.openStudentsDictionaryResultsDialog(
          finalMeanings,
          wordToFindToSend
        );
        // const dialogRef = this.dialog.open(StudentDictionaryDialogComponent, {
        //   width: "90vw",
        //   maxHeight: "90vh",
        //   data: {
        //     meanings: finalMeanings,
        //     fromButton: fromButton,
        //     connectedNotFound: connectedNotFound,
        //   },
        // });
        // }
        // else{
        //   let singleWord =  this.allWords.filter(word=> word.meanings?.some(meaning=>meaning.tags.some(
        //     tag=>
        //       tag.tag.trim() === wordToFindToSend.replace(' ', '  ')

        //     )))[0]
        //     if(singleWord){

        //       let finalMeanings = []
        //      singleWord.meanings?.forEach(meaning=>{
        //        let simpleTags = meaning.tags.map(tag=>{

        //          return tag.tag.trim()
        //        })
        //       if(simpleTags.some(tag=>tag.replace('  ', ' ') == wordToFindToSend)){
        //         finalMeanings.push(meaning)
        //       }
        //      })

        //         const dialogRef = this.dialog.open(StudentDictionaryDialogComponent, {
        //         width:'90vw',
        //         maxHeight:'90vh',
        //           data: {
        //             meanings:finalMeanings,
        //             fromButton:fromButton
        //           }
        //         });
        //       }
        // }
      });
  }

  async openStudentsDictionaryResultsDialog(foundWords, searchedWord, lang?) {
    const modal = await this.modalCtrl.create({
      component: StudentDictionaryDialogComponent,
      cssClass: 'full-height',
      backdropDismiss: false,

      componentProps: {
        foundWords,
        searchedWord,
        lang,
      },
    });

    modal.present();
    this.checkingTranslationForStudents = false;
  }
}
