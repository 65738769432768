import { Injectable } from '@angular/core';
import { UserDetailsService } from 'src/app/home/progress/user-details.service';
import { HelpersService } from './helpers.service';
import { HttpClient } from '@angular/common/http';
// for mark blank in quizzes there has to be blur set to at least 1
@Injectable({
  providedIn: 'root',
})
export class QuizParamsService {

  quizzes = []
  params = [];
  ///

  harderParams = [
    [
      {
        name: 'readFullBlankHiddenButtons',
        fixedPossible: true,
        descriptionForFixed: 'Wybierz'
      },
      {
        name: 'readFullBlankHiddenOnlyWriteAnswersClues',
      },
    ],
    [
      {
        name: 'blankHiddenButtons',
      },
      {
        name: 'readFullBlankHiddenOnlyWrite',

      },
      {
        name: 'blankHiddenTranslationButtons',
      },
      {
        name: 'translationOnlyWriteAnswersClues',
      },
    ],
    [
      {

        name: 'readFullBlurPercentage100MarkBlankMakeSentence',
        fixedPossible: true,
        descriptionForFixed: 'Utwórz zdanie'

      },


      {
        name: 'readFullMarkBlankBlurPercentage100SpeakSpeakSentenceSpeakingDifficultyMedium',
        fixedPossible: true,
        descriptionForFixed: 'Powiedz'
      },

      {
        name: 'blankHiddenOnlyWrite',

      },
      {
        name: 'blankHiddenOnlyWriteGif',

      },
    ],
  ];

  hardestParams5 = [
    [
      {
        name: 'readFullBlankHiddenButtons',
        fixedPossible: true,
        descriptionForFixed: 'Wybierz'
      },
      {
        name: 'readFullBlankHiddenOnlyWriteAnswersClues',
      },
      {
        name: 'readFullBlankHiddenOnlyWrite',
      },

    ],
    [
      {
        name: 'blankHiddenOnlyWrite',

      },
      {
        name: 'onlyWriteTranslation',

      },
    ],
    [
      {
        name: 'readFullBlurPercentage100MarkBlankMakeSentence',
        fixedPossible: true,
        descriptionForFixed: 'Powiedz'
      },

      {
        name: 'readFullMarkBlankBlurPercentage100SpeakSpeakSentenceSpeakingDifficultyMedium',
        fixedPossible: true,
        descriptionForFixed: 'Utwórz zdanie'
      }
    ],
  ];
  ////
  lightParams = [
    [
      {
        name: 'readFullBlankHiddenButtons',
      },
    ],

    [
      {
        name: 'translationOnlyWrite',
      }
    ],
    [
      {
        name: 'readFullBlurPercentage100MarkBlankMakeSentence',
        fixedPossible: true,
        descriptionForFixed: 'Utwórz zdanie'
      },
      {
        name: 'readFullMarkBlankBlurPercentage100SpeakSpeakSentenceSpeakingDifficultyMedium',
        fixedPossible: true,
        descriptionForFixed: 'Powiedz'
      }
    ],
  ];

  lightestParams1 = [
    [
      {
        name: 'onlyWriteMarkBlankBlurPercentage1',
        fixedPossible: true,
        descriptionForFixed: 'Napisz'
      },
    ],

    [
      {
        name: 'readFullBlankHiddenOnlyWriteAnswersClues',

      },
      {
        name: 'readFullBlankHiddenButtons',

      },
    ],
    [
      {
        name: 'readFullBlurPercentage100MarkBlankMakeSentenceReadingSpeed08',
        fixedPossible: true,
        descriptionForFixed: 'Utwórz zdanie'
      },
      {
        name: 'blankHiddenButtons',

      },
      {
        name: 'blankHiddenOnlyWriteAnswersClues',

      },
      {
        name: 'readFullMarkBlankBlurPercentage1SpeakSpeakSentenceSpeakingDifficultyMediumReadingSpeed08',
        fixedPossible: true,
        descriptionForFixed: 'Powiedz'
      },
      {
        name: 'blankHiddenTranslationButtons',
      }
    ],
  ];

  ///
  // quickParams = [
  //   [
  //     {
  //       name: 'fullSentenceWithSpeakingSentence',
  //       params: {
  //         multi: true,
  //         divide: true,
  //         readFull: true,
  //         light: true,
  //         blurPercentage: 0,

  //         speakSentence: true,
  //         speak: true,
  //         speakingDifficulty: 'medium',
  //         answerMode: 'sentence',
  //       },
  //       polishName: 'pełne | mowienie/m | zdanie',
  //     },
  //   ],
  // ];
  writeBlankParams = [
    [
      {
        name: 'fullSentenceWithSpeakingSentence',
        params: {
          multi: true,
          divide: true,
          onlyWrite: true,
        },
        polishName: 'pełne | mowienie/m | zdanie',
      },
    ],
  ];
  translateWriteParams = [
    [
      {
        name: 'fullSentenceWithSpeakingSentence',
        params: {
          multi: true,
          divide: true,
          translation: true,
          onlyWrite: true,
        },
        polishName: 'pełne | mowienie/m | zdanie',
      },
    ],
  ];
  translateChooseParams = [
    [
      {
        name: 'fullSentenceWithSpeakingSentence',
        params: {
          multi: true,
          divide: true,
          translation: true,
        },
        polishName: 'pełne | mowienie/m | zdanie',
      },
    ],
  ];
  originalParams = [];
  customParams: boolean;
  constructor(
    private http: HttpClient,
  ) {
    setTimeout(() => {
      console.log('staarting to loading quizzes...')
      this.loadQuizzes();
    }, 1000);


  }
  public loadQuizzes(): void {
    console.log('loading quizzes...')
    this.http.get<any>('https://content.lingking.pl/app-assets/quizzes_params.json')
      .subscribe(data => {
        console.log("🚀 ~ file: quiz-params.service.ts:355 ~ QuizParamsService ~ loadQuizzes ~ data:", data)
        this.quizzes = data; // Assuming the JSON structure matches the expected format
      });
  }
  setCustomParams(_name) {
    this.customParams = true
    console.log("🚀 ~ file: quiz-params.service.ts:836 ~ QuizParamsService ~ setCustomParams ~ _name:", _name)
    this.params = this.generateParams([[{ name: _name }]]);
    console.log("🚀 ~ file: quiz-params.service.ts:837 ~ QuizParamsService ~ setCustomParams ~  this.params:", this.params)

  }
  refreshParams(ageStatus, difficultyLevel) {
    if (!this.customParams) {
      this.params =
        ageStatus == 'mediumChild' ? this.generateParams(this.lightParams) : this.generateParams(this.harderParams);

      if (difficultyLevel == 1) {
        this.params = this.generateParams(this.lightestParams1);
      }
      if (difficultyLevel == 5) {
        this.params = this.generateParams(this.hardestParams5);
      }


      console.log(
        '🚀 ~ file: quiz-params.service.ts:298 ~ QuizParamsService ~ constructor ~ this.params:',
        this.params
      );
    }
  }
  generateParams(inputParams) {

    console.log("🚀 ~ file: quiz-params.service.ts:754 ~ QuizParamsService ~ generateParams ~ inputParams:", inputParams)
    let paramsToReturn = inputParams.map(paramsArray =>
      paramsArray.map(param => this.getParamsByName(param))
    );
    return paramsToReturn;
  }

  generateDescription(params) {
    let description: string = '';
    if (params?.readFull) {
      description += 'Czytanie: pełne zdanie. '
    }
    if (!params?.readFull && !params?.translation) {
      description += 'Czytanie: zdanie z blankiem. '
    }
    if (params?.translation) {
      description += 'Czytanie: tłumaczenie. '
    }
    if (params?.onlyWrite) {
      description += 'Rodzaj odpowiedzi: pisanie. '
    }
    if (params?.answersClues) {
      description += 'Podpowiedzi na obrazku: tak. '
    }
    if (params?.makeSentence) {
      description += 'Rodzaj odpowiedzi: układanie zdania. '
    }
    if (params?.speak) {
      description += 'Rodzaj odpowiedzi: mówienie. '
    }
    if (params?.gif) {
      description += 'Obrazek: placeholder. '
    }
    if (!params?.gif) {
      description += 'Obrazek: Ze słowa. '
    }
    if (params?.speakSentence) {
      description += 'Rodzaj odpowiedzi: mówienie zdania. '
    }
    if (params?.translation) {
      description += 'Rodzaj Zadania: tłumaczenie. '
    }
    if (!params?.translation) {
      description += 'Rodzaj Zadania: zdanie. '
    }
    if (!params?.speakSentence && !params?.speak && !params?.speakSentence && !params?.translation && !params?.makeSentence && !params?.onlyWrite) {
      description += 'Rodzaj Odpowiedzi: przyciski. '
    }
    if (params?.markBlank) {
      description += 'Zaznaczanie blanków: tak. '
    }
    if (!params?.markBlank) {
      description += 'Zaznaczanie blanków: nie. '
    }
    if (params?.blurPercentage) {
      description += 'Rozmycie: ' + params.blurPercentage + '%. '
    }
    if (!params?.blurPercentage) {
      description += 'Rozmycie: 0%. '
    }
    if (params?.readingSpeed) {
      description += 'Szybkość czytania: ' + params.readingSpeed + '. '
    }
    if (!params?.readingSpeed) {
      description += 'Szybkość czytania: 1. '
    }
    if (params?.speakingDifficulty) {
      description += 'Trudność mówienia: ' + params.speakingDifficulty + '. '
    }
    return description;
  }

  getParamsByName(inputParams) {
    let params: any = this.quizzes.find((params: any) => {
      return params.name == inputParams.name;
    });
    if (params) {

      if (inputParams?.fixedPossible) {
        params.fixedPossible = inputParams?.fixedPossible;
      }
      if (inputParams?.descriptionForFixed) {
        params.descriptionForFixed = inputParams?.descriptionForFixed;
      }

      params.polishName = this.generateDescription(params.params)
      console.log("🚀 ~ file: quiz-params.service.ts:803 ~ QuizParamsService ~ params ~ params:", params)
    }
    return params;
  }




}
