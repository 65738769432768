import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToolbarWMenuComponent } from './components/toolbar-w-menu/toolbar-w-menu.component';
import { IonicModule } from '@ionic/angular';
import { SetsProgressDiamondsComponent } from './components/sets-progress-diamonds/sets-progress-diamonds.component';
import { SentenceWordConstructorComponent } from './components/sentence-word-constructor/sentence-word-constructor.component';
import { StudentDictionaryDialogComponent } from './components/student-dictionary-dialog/student-dictionary-dialog.component';
import { DisassembledTextConstructorComponent } from './components/disassembled-text-constructor/disassembled-text-constructor.component';
import { SetContentCardComponent } from './components/set-content-card/set-content-card.component';
import { KeyboardComponent } from './components/keyboard/keyboard.component';
import { DisablePullToRefreshDirective } from './directives/disable-pull-to-refresh.directive';
import { NoLetterInputDirective } from './directives/no-letter-input.directive';
import { UserAvatarSmallComponent } from './components/user-avatar-small/user-avatar-small.component';
import { SpeechRecognitionComponent } from '../home/learn/quiz-wrapper/quizzes/multi-quiz/questions-quiz/speech-recognition/speech-recognition.component';
import { TimerComponent } from './components/timer/timer.component';
import { CdkDropList, DragDropModule } from '@angular/cdk/drag-drop';
import { RoundIconButtonComponent } from './components/round-icon-button/round-icon-button.component';
import { CollectablesViewComponent } from './components/collectables/collectables-view/collectables-view.component';
import { CollectablePlanetswrapperComponent } from './components/collectables/collectable-planets-wrapper/collectable-planets-wrapper.component';
import { CollectablePlanetComponent } from './components/collectables/collectable-planet/collectable-planet.component';
import { CollectableCardComponent } from './components/collectables/collectable-card/collectable-card.component';
import { ChoosePrizeComponent } from './components/collectables/choose-prize/choose-prize.component';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

const config: SocketIoConfig = { url: 'http://localhost:3010', options: {} };

@NgModule({
  declarations: [
    ToolbarWMenuComponent,
    DisassembledTextConstructorComponent,
    SetContentCardComponent,
    SetsProgressDiamondsComponent,
    SentenceWordConstructorComponent,
    StudentDictionaryDialogComponent,
    KeyboardComponent,
    DisablePullToRefreshDirective,
    NoLetterInputDirective,
    UserAvatarSmallComponent,
    SpeechRecognitionComponent,
    TimerComponent,
    RoundIconButtonComponent,
    CollectablesViewComponent,
    CollectablePlanetswrapperComponent,
    CollectablePlanetComponent,
    CollectableCardComponent,
    ChoosePrizeComponent,
  ],
  imports: [
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    FormsModule,
    IonicModule,
    DragDropModule,
    InfiniteScrollModule,
    // SocketIoModule.forRoot(config),
  ],
  exports: [
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    DragDropModule,
    SetContentCardComponent,
    ToolbarWMenuComponent,
    SetsProgressDiamondsComponent,
    SentenceWordConstructorComponent,
    DisassembledTextConstructorComponent,
    StudentDictionaryDialogComponent,
    KeyboardComponent,
    NoLetterInputDirective,
    SpeechRecognitionComponent,
    UserAvatarSmallComponent,
    TimerComponent,
    RoundIconButtonComponent,
    CollectablesViewComponent,
    InfiniteScrollModule,
  ],
})
export class SharedModule { }
