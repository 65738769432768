import { Injectable } from '@angular/core';
import { PlatformService } from './platform.service';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { HelpersService } from './helpers.service';
import { AuthenticationService } from 'src/app/authentication/authentication.service';
import { UserDetailsService } from 'src/app/home/progress/user-details.service';

@Injectable({
  providedIn: 'root',
})
export class SetsService {
  url;
  setContent = new Subject();

  constructor(
    private platform: PlatformService,
    private http: HttpClient,
    private helpers: HelpersService,
    private authService: AuthenticationService,
    private userDetailsService: UserDetailsService
  ) {
    this.url = platform.url;
  }

  getSetContent(id, usersId) {
    this.http
      .post<{ sets: any }>(this.url + '/api/sets/getset', { id })
      .pipe()
      .subscribe((set) => {
        const _set = set.sets[0];

        let translatedSet = this.helpers.translateObjectFromBackend(_set);
        translatedSet.itemsLoaded = true;
        translatedSet.parentSet = id;
        // translatedSet.id = usersId;
        this.setContent.next(translatedSet);
        console.log(
          '🚀 ~ file: sets.service.ts:32 ~ SetsService ~ .subscribe ~ translatedSet:',
          translatedSet
        );

        // this.checkAudio('wordAudio')
      });
  }
  addSetToUser(userId, set) {
    console.log(
      '🚀 ~ file: sets.service.ts:44 ~ SetsService ~ addSetToUser ~ set:',
      set
    );
    console.log(
      '🚀 ~ file: sets.service.ts:44 ~ SetsService ~ addSetToUser ~ userId:',
      userId
    );
    console.log('adding set to user...');
    let setToSend = this.helpers.clone(set);
    setToSend.items = setToSend.items.map((item) => {
      return { wordId: item.wordId, meaningId: item.meaningId, item: {} };
    });
    console.log(
      '🚀 ~ file: sets.service.ts:60 ~ SetsService ~ setToSend.items=setToSend.items.map ~  setToSend:',
      setToSend
    );
    // setToSend.id = this.helpers.makeid(8);
    // set.id = setToSend.id;
    this.http
      .put(
        this.url + '/api/users/addsettouser/' + userId,
        { set: setToSend },
        { headers: { Authorization: `Bearer ${this.authService.getToken()}` } }
      )
      .subscribe((response) => { });
  }
  searchForSetsbyId(setId) {
    this.http
      .post<{ sets: any }>(this.url + '/api/sets/findsetbyid', {
        setToFind: setId,
      })
      .pipe()
      .subscribe((set) => {
        const _set = set.sets[0];
        const translatedSet = this.helpers.translateObjectFromBackend(_set);
        this.setContent.next(translatedSet);
      });
  }
}
