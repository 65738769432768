import { ChangeDetectorRef, Injectable } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { PlatformService } from 'src/app/shared/services/platform.service';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from '../../authentication/authentication.service';
import { HelpersService } from '../../shared/services/helpers.service';
import { SetsService } from '../../shared/services/sets.service';
import { datadogRum } from '@datadog/browser-rum';
import { RtcService } from 'src/app/shared/services/rtc.service';
import { Router } from '@angular/router';
import { version } from '../../../environments/version';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import { QuizParamsService } from 'src/app/shared/services/quiz-params.service';

@Injectable({
  providedIn: 'root',
})
export class UserDetailsService {
  smallChild = false;
  mediumChild = false;
  largeChild = false;
  customer = false;
  userDetails: any;
  getSetsAllowed = true;
  userDetailsAvailable = false;
  userDetailsBearer = new Subject();
  currentSet;
  interval;
  private url: string;
  version = version;
  private profileSub: Subscription;
  currentSetStats: any;
  asd: any;
  alsoStudent: boolean;
  timer;
  sub: Subscription;
  latestSetsUpdated = new Subject<any>();
  finishedSetsUpdated = new Subject<any>();
  unfinishedSetsUpdated = new Subject<any>();
  ageStatus: string;
  difficultyLevel: number;
  constructor(
    private platform: PlatformService,
    private http: HttpClient,
    private authService: AuthenticationService,
    private setsService: SetsService,
    private spinnerService: SpinnerService,
  ) {
    document.addEventListener('visibilitychange', () =>
      this.handleVisibilityChange()
    );

    this.url = platform.url;
    if (!this.profileSub) {
      this.profileSub = this.authService.profile().subscribe((user) => {
        console.log(
          '🚀 ~ file: user-details.service.ts:38 ~ UserDetailsService ~ this.profileSub=this.authService.profile ~ user:',
          user
        );

        if (user.userData) {


          // if (user.role == 'customer' && !user.userData?.alsoStudent) {
          //   this.router.navigateByUrl('/customer-panel');
          //   return;
          // } else {
          // this.router.navigateByUrl('/home/profile');
          // }

        }
        if (user?.role === 'customer') {
          this.customer = true;
        }
        if (user?.userData?.alsoStudent) {
          this.alsoStudent = true;
        }

        this.storeUserDetails(user);

        datadogRum.setUser({
          name: user.name,
        });
        if (this.platform.env !== 'dev') {
          datadogRum.init({
            applicationId: '200e3c6b-26ff-4b39-9bbe-c9d2f308daaf',
            clientToken: 'pub00436916c5ed35c4e66876f11b0bc130',
            site: 'datadoghq.eu',
            service: 'ionic',
            env: '<ENV_NAME>',
            // Specify a version number to identify the deployed version of your application in Datadog
            version: this.version,

            sessionSampleRate: 100,
            trackUserInteractions: true,
            trackResources: true,
            trackLongTasks: true,
            defaultPrivacyLevel: 'mask-user-input',
          });
          datadogRum.startSessionReplayRecording();
        }
        this.checkAgeStatus();
        this.checkDifficultyLevel();
      });
    }
    this.setsService.setContent.asObservable().subscribe((setContent: any) => {
      let setToReplace = this.userDetails.userData.sets.find(
        (set) => set.parentSet === setContent.parentSet
      );
      if (setToReplace) {
        setToReplace = setContent;
        this.getUserDetails();
      }
    });
  }
  private handleVisibilityChange(): void {
    const currentTime = new Date().getTime();

    if (document.hidden) {
      // Store the current time when the tab goes inactive
      localStorage.setItem('inactiveTime', currentTime.toString());
      console.log('Tab is inactive');
    } else {
      // Retrieve the stored time and calculate the duration of inactivity
      const inactiveTime = localStorage.getItem('inactiveTime');
      console.log('Tab is active');
      if (inactiveTime) {
        const duration = currentTime - Number(inactiveTime);

        // Check if the tab has been inactive for more than 5 seconds (5000 milliseconds)
        if (duration > 300000) {
          console.log('Tab has been inactive for more than 60 seconds');
          window.location.reload();
        }

        // Remove the stored time
        localStorage.removeItem('inactiveTime');
      }
    }
  }

  getUsersProgress(usersIds) {
    this.http
      .post(this.url + '/api/users/get-users-progress/', usersIds, {
        headers: { Authorization: `Bearer ${this.authService.getToken()}` },
      })
      .subscribe((response) => {
        console.log(
          '🚀 ~ file: users-service.service.ts ~ line 116 ~ UsersServiceService ~ resetWeeklyProgress ~ response',
          response
        );
      });
  }

  resetProgress() {
    let numberMilisecondsFromLastReset =
      Date.now() - this.userDetails.userData.lastResetProgress;

    console.log(
      '🚀 ~ file: user-details.service.ts:150 ~ UserDetailsService ~ resetProgress ~ numberMilisecondsFromLastReset:',
      numberMilisecondsFromLastReset
    );
    if (numberMilisecondsFromLastReset < 0) {
      numberMilisecondsFromLastReset = -numberMilisecondsFromLastReset;
    }
    if (numberMilisecondsFromLastReset > 604800000 || !this.userDetails.userData.lastResetProgress) {
      this.spinnerService.showSpinner();
      console.error('resetting progress');
      this.http
        .put(
          this.url + '/api/users/reset-weekly-progress/' + this.userDetails._id,
          {
            headers: {
              Authorization: `Bearer ${this.authService.getToken()}`,
            },
          }
        )
        .subscribe((response) => {
          console.log(
            '🚀 ~ file: users-service.service.ts ~ line 116 ~ UsersServiceService ~ resetWeeklyProgress ~ response',
            response
          );
        }),
        (error) => {
          console.log(
            '🚀 ~ file: users-service.service.ts ~ line 119 ~ UsersServiceService ~ resetWeeklyProgress ~ error',
            error
          );
        };

      setTimeout(() => {
        window.location.reload();
        this.spinnerService.hideSpinner();
      }, 3000);
    }
  }
  addusersCollectableItems(userId, collectableItem) {
    const url = this.url + '/api/users/adduserscollectableitems/' + userId;
    console.log(url);
    this.http
      .put(
        this.url + '/api/users/adduserscollectableitems/' + userId,
        { collectableItem: collectableItem },
        { headers: { Authorization: `Bearer ${this.authService.getToken()}` } }
      )
      .subscribe((response) => { });
  }
  storeUserDetails(userDetails) {
    this.userDetails = userDetails;
    if (
      this.userDetails.role == 'customer' &&
      !this.userDetails.userData?.alsoStudent
    ) {
    } else {
      this.getLatestSets(userDetails._id ? userDetails._id : userDetails.id);
    }
    if (this.userDetails) {
      this.userDetailsBearer.next(this.userDetails);
      if (
        userDetails?.role === 'student' ||
        userDetails?.userData?.alsoStudent
      ) {
        this.resetProgress();
      }
    }
  }
  clearUserDetails() {
    this.userDetails = null;
    this.userDetailsAvailable = false;
    this.userDetailsBearer.next(null);
    this.currentSet = null;
  }
  getUserDetails() {
    if (this.userDetails) {
      this.userDetailsBearer.next(this.userDetails);
      return this.userDetails;
    }
    if (!this.userDetails) {
      if (!this.sub) {
        this.sub = this.authService.profile().subscribe((user) => {
          this.userDetails = user;
          this.userDetailsBearer.next(this.userDetails);
          this.sub.unsubscribe();
          return this.userDetails;
        });
      }
    }
  }

  checkAgeStatus() {
    const ud: any = this.getUserDetails();
    if (ud) {
      switch (ud.userData?.ageStatus) {
        case 'smallChild':
          this.smallChild = true;
          this.ageStatus = 'smallChild';
          break;
        case 'mediumChild':
          this.mediumChild = true;
          this.ageStatus = 'mediumChild';
          break;
        case 'largeChild':
          this.largeChild = true;
          this.ageStatus = 'largeChild';
          break;

        default:
          break;
      }

      console.log("🚀 ~ file: user-details.service.ts:265 ~ UserDetailsService ~ checkAgeStatus ~ this.ageStatus:", this.ageStatus)
      return ud.userData?.ageStatus;
    } else {
      return null;
    }
  }
  checkDifficultyLevel() {
    const ud: any = this.getUserDetails();
    if (ud) {
      this.difficultyLevel = Number(ud.userData?.difficultyLevel);
      return Number(ud.userData?.difficultyLevel);
    }
  }
  getSimpleUserDetails() {
    const ud: any = this.getUserDetails();
    if (ud) {
      return {
        id: ud.id ? ud.id : ud._id,
        name: ud.name,
        role: ud.role,
        avatar: ud.avatar ? ud.avatar : null,
      };
    } else {
      return null;
    }
  }

  storeCurrentSet(set) {
    this.currentSet = set;
    let currentSetInUserSets = this.userDetails.userData.sets.find(
      (_set) => _set?.parentSet === set.parentSet || _set?.id === set.id
    );
    if (currentSetInUserSets) {
      currentSetInUserSets = set;
    } else {
      this.userDetails.userData.sets.push(set);
    }
    this.userDetailsBearer.next(this.userDetails);
  }
  storeCurrentSetsStats(stats) {
    this.currentSetStats = stats;
    this.userDetailsBearer.next(this.userDetails);
  }
  getCurrentSetsStats() {
    return this.currentSetStats;
  }

  getCurrentSet() {
    return this.currentSet;
  }

  getUsersSets(id) {
    if (id) {
      this.http
        .post(
          this.url + '/api/users/getuserssetswithgroupssets',
          { studentId: id },
          {
            headers: { Authorization: `Bearer ${this.authService.getToken()}` },
          }
        )
        .subscribe((response: any) => {
          response.sets.forEach((set) => {
            if (set?.stats?.quizParams?.lastLearned) {
              set.lastLearned = set?.stats?.quizParams?.lastLearned;
            } else {
              set.lastLearned = 0
            }
          });
          this.userDetails.userData.sets = response.sets;
          this.latestSetsUpdated.next(response.sets);
          const newSets = response.sets.filter(
            (set) => !set.lastLearned && !set.stats
          );


          this.userDetails.userData.newSets =
            this.userDetails.userData.sets.filter((set) => set.new);
          // this.userDetails.userData.sets =
          //   this.userDetails.userData.sets.filter((set) => !set.new);
          this.getUserDetails();
        });
    }
  }
  getLatestSets(id) {
    if (id) {
      this.http
        .post(
          this.url + '/api/users/get-latest-sets',
          { studentId: id },
          {
            headers: { Authorization: `Bearer ${this.authService.getToken()}` },
          }
        )
        .subscribe((response: any) => {
          response = response?.filter((set) => set);
          response.

            forEach((set) => {
              if (set?.stats?.quizParams?.lastLearned) {
                set.lastLearned = set?.stats?.quizParams?.lastLearned;
              } else {
                if (set) {
                  set.lastLearned = 0
                }
              }
            });
          this.latestSetsUpdated.next(response);
        });
    }
  }
  getFinishedSets(id) {
    if (id) {
      this.http
        .post(
          this.url + '/api/users/get-finished-sets',
          { studentId: id },
          {
            headers: { Authorization: `Bearer ${this.authService.getToken()}` },
          }
        )
        .subscribe((response: any) => {
          response.

            forEach((set) => {
              if (set?.stats?.quizParams?.lastLearned) {
                set.lastLearned = set?.stats?.quizParams?.lastLearned;
              } else {
                set.lastLearned = 0
              }
            });
          this.finishedSetsUpdated.next(response);
        });
    }
  }
  getUnfinishedSets(id) {
    if (id) {
      this.http
        .post(
          this.url + '/api/users/get-unfinished-sets',
          { studentId: id },
          {
            headers: { Authorization: `Bearer ${this.authService.getToken()}` },
          }
        )
        .subscribe((response: any) => {
          response.

            forEach((set) => {
              if (set?.stats?.quizParams?.lastLearned) {
                set.lastLearned = set?.stats?.quizParams?.lastLearned;
              } else {
                set.lastLearned = 0
              }
            });
          this.unfinishedSetsUpdated.next(response);
        });
    }
  }


  userDetailsListener() {
    return this.userDetailsBearer.asObservable();
  }

  latestSetsUpdatedListener() {
    return this.latestSetsUpdated.asObservable();

  }
  finishedSetsUpdatedListener() {
    return this.finishedSetsUpdated.asObservable();
  }
  unfinishedSetsUpdatedListener() {
    return this.unfinishedSetsUpdated.asObservable();
  }

}
